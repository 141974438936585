import { createSelector } from '@reduxjs/toolkit';
import { organizationSelectors } from 'shared/Redux/slices';
import isDeleted from 'shared/Redux/api/isDeleted';

const selectOrganizations = createSelector(
  [organizationSelectors.selectAll],
  organizationsAll => organizationsAll?.filter(o => !isDeleted(o))
);

export default selectOrganizations;
