/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import {
  Box,
  DefaultTheme,
  Icon,
  makeStyles
} from 'groundkeeper-component-library';

const useStyles = makeStyles((theme: DefaultTheme) => ({
  link: {
    '&:hover': {
      textDecoration: 'underline !important',
      cursor: 'pointer'
    },
    '& svg': {
      marginLeft: (props: { showArrow?: boolean }) =>
        props.showArrow ? '.25rem' : 0
    }
  },
  disabled: {
    cursor: 'default',
    color: theme.color.text.link,
    opacity: (props: PropTypes.InferProps<typeof Link.propTypes>) =>
      props.disabled ? 0.78 : 1
  }
}));

export default function Link(
  props: PropTypes.InferProps<typeof Link.propTypes>
) {
  const router = useRouter();
  const classes = useStyles(props);

  // TBD: Add support for replace, shallow
  async function handleClick(e) {
    e.preventDefault();
    await props.onClick();

    if (props.href) router.push(props.href);
  }

  const withArrow = props.showArrow ? (
    <Box display="flex">
      {props.children}
      <Icon lib="fa" color="link" name="arrow-right" size="small" />
    </Box>
  ) : (
    props.children
  );

  if (props.disabled) {
    return (
      <div
        className={classes.disabled}
        title={props.title}
        aria-disabled={props.disabled}
        data-testid={props['data-testid']}
      >
        {withArrow}
      </div>
    );
  }

  return props.onClick ? (
    <a
      title={props.title}
      style={props.style}
      className={classes.link}
      onClick={e => handleClick(e)}
      data-testid={props['data-testid']}
      data-mainlink={props['data-mainlink']}
    >
      {withArrow}
    </a>
  ) : (
    <NextLink
      as={props.as}
      href={props.href}
      passHref={props.passHref}
      prefetch={props.prefetch}
      replace={props.replace}
      scroll={props.scroll}
      shallow={props.shallow}
      legacyBehavior
    >
      <a
        title={props.title}
        className={classes.link}
        style={props.style}
        data-testid={props['data-testid']}
        data-mainlink={props['data-mainlink']}
        aria-disabled={props.disabled}
      >
        {withArrow}
      </a>
    </NextLink>
  );
}

Link.propTypes = {
  onClick: PropTypes.func,
  as: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  passHref: PropTypes.bool,
  disabled: PropTypes.bool,
  prefetch: PropTypes.bool,
  replace: PropTypes.bool,
  showArrow: PropTypes.bool,
  ['data-mainlink']: PropTypes.bool,
  scroll: PropTypes.bool,
  shallow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object
};

Link.defaultProps = {
  as: null,
  title: null,
  onClick: null,
  passHref: false,
  disabled: false,
  prefetch: undefined,
  showArrow: false,
  ['data-mainlink']: false,
  replace: false,
  scroll: true,
  shallow: false,
  style: { textDecoration: 'none' }
};
