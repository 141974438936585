import Image from 'next/image';
import PropTypes from 'prop-types';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  Box,
  Clickable,
  DefaultTheme,
  Footer,
  Heading,
  Icon,
  Spacing,
  Text,
  Topbar,
  makeStyles
} from 'groundkeeper-component-library';
import { IntlShape, useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import Drawer from './Drawer';
import Link from './Link';

import Globals from 'shared/App/utils/Globals';
import berlinLogoNegativ from 'public/berlin-logo-negativ.png';
import selectOrganizations from 'shared/Redux/selectors/organization/selectOrganizations';

const prodEnvNames = ['PROD', 'PRODUCTION', '[PROD]', '[PRODUCTION]'];

const MENU = ({
  intl,
  portalBookingRequests
}: {
  intl: IntlShape;
  portalBookingRequests: Boolean;
}): {
  title: string;
  href?: string;
  quick?: boolean;
  onClick?: () => void;
}[] => {
  const menuList = [
    {
      title: intl.formatMessage({
        id: 'groups_find',
        defaultMessage: 'Find groups'
      }),
      href: '/groups?unbookable=false',
      quick: false
    }
  ];

  if (portalBookingRequests) {
    menuList.push({
      title: intl.formatMessage({
        id: 'request_create',
        defaultMessage: 'Request create'
      }),
      href: '/requests/create',
      quick: false
    });
  }

  menuList.push({
    title: intl.formatMessage({
      id: 'groups_explore',
      defaultMessage: 'Explore groups'
    }),
    href: '/map',
    quick: false
  });

  menuList.push({
    title: intl.formatMessage({
      id: 'faq',
      defaultMessage: 'Frequently asked Questions (FAQ)'
    }),
    href: '/faq',
    quick: false
  });

  return menuList;
};

const FOOTER_PRIMARY = (intl: IntlShape) => [
  {
    title: 'Service',
    entries: [
      { title: 'Service-App', href: 'https://service.berlin.de/app/' },
      {
        title: 'Termin vereinbaren',
        href: 'https://service.berlin.de/terminvereinbarung/'
      },
      {
        title: 'Bürgertelefon 115',
        href: 'https://service.berlin.de/buergertelefon/'
      },
      {
        title: 'Notdienste',
        href: 'https://www.berlin.de/polizei/service/so-erreichen-sie-uns/artikel.532842.php'
      },
      { title: 'Gewerbeservice', href: 'https://www.berlin.de/ea/' }
    ]
  },
  {
    title: 'Behörden',
    entries: [
      { title: 'Behörden A-Z', href: 'https://service.berlin.de/behoerden/' },
      {
        title: 'Senatsverwaltungen',
        href: 'https://service.berlin.de/senatsverwaltungen/'
      },
      {
        title: 'Bezirksämter',
        href: 'https://service.berlin.de/bezirksaemter/'
      },
      {
        title: 'Bürgerämter',
        href: 'https://service.berlin.de/standorte/buergeraemter/'
      },
      { title: 'Jobcenter', href: 'https://service.berlin.de/jobcenter/' },
      { title: 'Einwanderungsamt', href: 'https://www.berlin.de/einwanderung/' }
    ]
  },
  {
    title: 'Politik & Verwaltung',
    entries: [
      {
        title: 'Landesregierung',
        href: 'https://www.berlin.de/rbmskzl/politik/senat/senatsmitglieder/'
      },
      {
        title: 'Karriere im Land Berlin',
        href: 'https://www.berlin.de/karriereportal/'
      },
      {
        title: 'Bürgerbeteiligung',
        href: 'https://mein.berlin.de'
      },
      {
        title: 'Open Data',
        href: 'https://daten.berlin.de'
      },
      { title: 'Vergaben', href: 'https://www.berlin.de/vergabeplattform/' },
      { title: 'Ehrenamt', href: 'https://www.berlin.de/buergeraktiv/' }
    ]
  },
  {
    title: 'Aktuelles',
    entries: [
      {
        title: 'Sofortprogramm des Senats',
        href: 'https://www.berlin.de/rbmskzl/politik/sofortprogramm/'
      },
      { title: 'Ukraine', href: 'https://www.berlin.de/ukraine/' },
      { title: 'Energiekrise', href: 'https://www.berlin.de/energie/' },
      { title: 'Pressemitteilungen', href: 'https://www.berlin.de/presse/' },
      {
        title: 'Polizeimeldungen',
        href: 'https://www.berlin.de/polizei/polizeimeldungen/'
      },
      { title: 'Veranstaltungen', href: 'https://www.berlin.de/land/kalender/' }
    ]
  },
  {
    title: 'Themen',
    entries: [
      {
        title: 'Gemeinsam Digital',
        href: 'https://gemeinsamdigital.berlin.de/'
      },
      {
        title: 'Grundsteuer',
        href: 'https://berlin.de/grundsteuer'
      },
      {
        title: 'Mobilitätswende',
        href: 'https://www.berlin.de/sen/uvk/verkehr/mobilitaetswende/verkehr-888873.php'
      },
      {
        title: 'Moderne Verwaltung',
        href: 'https://www.berlin.de/moderne-verwaltung/'
      },
      { title: 'Berliner Mauer', href: 'https://www.berlin.de/mauer/' }
    ]
  },
  {
    title: 'Weitere Informationen',
    entries: [
      {
        title: 'Kultur & Ausgehen',
        href: 'https://www.berlin.de/kultur-und-tickets/'
      },
      { title: 'Tourismus', href: 'https://www.berlin.de/tourismus/' },
      { title: 'Wirtschaft', href: 'https://www.berlin.de/wirtschaft/' },
      { title: 'Stadtleben', href: 'https://www.berlin.de/special/' },
      { title: 'BerlinFinder', href: 'https://www.berlin.de/adressen/' },
      { title: 'Stadtplan', href: 'https://www.berlin.de/stadtplan/' }
    ]
  }
];

const FOOTER_SECONDARY = (intl: IntlShape) => [
  {
    title: intl.formatMessage({
      id: 'sports_portal',
      defaultMessage: 'Sports portal'
    }),
    entries: [
      {
        title: 'Impressum',
        href: '/impressum'
      },
      {
        title: 'Kontakt',
        href: '/contact'
      },
      {
        title: 'Häufig gestellte Fragen',
        href: '/faq'
      },
      {
        title: 'Datenschutzerklärung',
        href: '/privacy-policy'
      },
      {
        title: 'Digitale Barrierefreiheit',
        href: '/barrier-free-policy'
      }
    ]
  }
];

interface MakeStyeProps {
  state: {
    scrolling: boolean;
    topbarHeights: {
      primary: number;
      secondary: number;
    };
  };
}

const useStyles = makeStyles((theme: DefaultTheme) => ({
  '@global': {
    body: {
      minWidth: '100%',
      overflowX: 'hidden'
    },
    form: {
      background: theme.color.background.grey,
      padding: '1.125rem'
    },
    fieldset: {
      border: 0,
      borderBottom: `1px solid ${theme.color.border.main}`,
      padding: 0,
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(1)
    }
  },
  header: {
    marginTop: ({ state }: MakeStyeProps) =>
      state.scrolling ? state.topbarHeights.secondary : null
  },
  primaryTopbar: {
    top: 0,
    position: ({ state }) => (state.scrolling ? 'fixed' : 'relative'),
    height: ({ state }) => (state.scrolling ? 3 : state.topbarHeights.primary),
    transition: 'height .5s',
    background: ({ state }) =>
      state.scrolling ? theme.color.common.grey.dark : 'transparent',
    zIndex: 99
  },
  secondaryTopbar: {
    position: ({ state }) => (state.scrolling ? 'fixed' : 'relative'),
    top: 3,
    zIndex: 99
  },
  logo: {
    paddingLeft: theme.spacing(3),
    [theme.mediaQueries.small]: {
      paddingLeft: '.75rem'
    }
  },
  layout: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) minmax(5rem, 61.25rem) minmax(0, 1fr)',
    columnGap: '80px',
    [theme.mediaQueries.medium]: {
      columnGap: '40px'
    },
    [theme.mediaQueries.small]: {
      columnGap: 0,
      padding: '0 .75rem'
    }
  },
  content: {
    paddingBottom: theme.spacing(7),
    position: 'relative'
  },
  pageHeader: {
    paddingLeft: theme.spacing(3),
    '& a': {
      color: theme.color.text.main,
      '&:hover': {
        textDecoration: 'none !important'
      }
    },
    '& span': {
      display: 'block',
      fontSize: '1.25rem'
    },
    [theme.mediaQueries.small]: {
      paddingLeft: '.75rem',
      paddingTop: '.75rem',
      paddingBottom: '.75rem',
      '& span': {
        fontSize: '1rem'
      }
    }
  },
  pageHeaderTitle: {
    fontWeight: 'bold'
  },
  quickNavigation: {
    display: 'flex',
    alignSelft: 'flex-end',
    fontSize: '1.1rem',
    '& ul': {
      display: 'flex',
      margin: 0,
      padding: 0,
      listStyleType: 'none'
    },
    '& li': {
      margin: '3px 0vw 3px 3vw'
    },
    '& a': {
      color: theme.color.text.main,
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    [theme.mediaQueries.small]: {
      display: 'none'
    }
  },
  serviceButtons: {
    display: 'flex',
    textAlign: 'center',
    '& button': {
      cursor: 'pointer',
      display: 'block',
      background: 'none',
      border: 'none',
      fontSize: '1rem',
      '&:hover': {
        background: 'none'
      }
    },
    '& svg': {
      margin: '0 auto',
      width: 'auto',
      height: '2rem'
    },
    '& span': {
      fontSize: '.6875rem'
    }
  },
  drawerHeader: {
    '& h2': {
      margin: 0
    }
  },
  drawerNavigation: {
    '& ul': {
      margin: 0,
      padding: 0,
      listStyleType: 'none'
    },
    '& li': {
      borderTop: `1px solid ${theme.color.border.light}`
    },
    '& a': {
      display: 'block',
      color: theme.color.text.main,
      boxSizing: 'border-box',
      width: '100%',
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }
}));

function Layout({ children }: { children: ReactNode }) {
  const primaryTopbarRef = useRef<HTMLDivElement>();
  const secondaryTopbarRef = useRef<HTMLDivElement>();
  const [topbarHeights, setTopbarHeight] = useState({
    primary: undefined,
    secondary: undefined
  });
  const [scrolling, setScrolling] = useState(false);
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);
  const [barrierFreeDrawerOpen, setBarrierFreeDrawerOpen] = useState(false);
  const classes = useStyles({
    state: { scrolling, topbarHeights }
  });
  const intl = useIntl();
  const router = useRouter();
  const organization = useSelector(selectOrganizations)?.[0];

  useEffect(() => {
    function hideDrawer() {
      setMenuDrawerOpen(false);
      setBarrierFreeDrawerOpen(false);
    }

    function handleTopbarScroll() {
      const scrollPosition =
        document.body.scrollTop || document.documentElement.scrollTop;

      if (scrollPosition > 0) {
        setScrolling(true);
      }

      if (scrollPosition === 0) {
        setScrolling(false);
      }
    }

    setTopbarHeight({
      primary: primaryTopbarRef.current.clientHeight,
      secondary: secondaryTopbarRef.current.clientHeight
    });

    router.events.on('routeChangeStart', hideDrawer);
    window.addEventListener('scroll', handleTopbarScroll);

    return () => {
      router.events.off('routeChangeStart', hideDrawer);
      window.removeEventListener('scroll', handleTopbarScroll);
    };
  }, []);

  const portalBookingRequests = organization?.setting_portal_booking_requests;

  return (
    <Box display="flex" flexDirection="column">
      <header className={classes.header}>
        <Topbar
          ref={primaryTopbarRef}
          // @ts-ignore: no-typescript-component
          className={classes.primaryTopbar}
          logo={
            scrolling ? null : (
              <Link href="https://www.berlin.de/">
                <Box width={100} pt={0.6} className={classes.logo}>
                  <img
                    src="/berlin-logo.svg"
                    alt="Berlin Logo"
                    title="Link zu: Startseite Berlin.de"
                    height={33.3333}
                    width={100}
                  />
                </Box>
              </Link>
            )
          }
        />
        {/* @ts-ignore: no-typescript-component */}
        <Topbar
          ref={secondaryTopbarRef}
          className={classes.secondaryTopbar}
          size="large"
          logo={
            <Box py={2.5} className={classes.pageHeader}>
              <Link href="/">
                <span className={classes.pageHeaderTitle}>
                  {Globals.settings.appEnv &&
                  !prodEnvNames.includes(Globals.settings.appEnv.toUpperCase())
                    ? `${Globals.settings.appEnv.toUpperCase()} ${intl.formatMessage(
                        {
                          id: 'sports_portal',
                          defaultMessage: 'Sports portal'
                        }
                      )}`
                    : intl.formatMessage({
                        id: 'sports_portal',
                        defaultMessage: 'Sports portal'
                      })}
                </span>
              </Link>
            </Box>
          }
        >
          <nav
            className={classes.quickNavigation}
            aria-label="Bereichsnavigation"
          >
            <ul>
              {MENU({
                intl,
                portalBookingRequests
              })
                .filter(item => item.quick)
                .map(item => (
                  <li key={item.title}>
                    {item.href ? (
                      <Link href={item.href}>{item.title}</Link>
                    ) : (
                      <Clickable onClick={item.onClick}>
                        <a>{item.title}</a>
                      </Clickable>
                    )}
                  </li>
                ))}
            </ul>
          </nav>
          <Box ml="2vw" pr={2.5} className={classes.serviceButtons}>
            <button onClick={() => setBarrierFreeDrawerOpen(true)}>
              <Icon lib="fa" name="universal-access" color="text" />
              <span>Barrierefrei</span>
            </button>
            <button onClick={() => setMenuDrawerOpen(true)}>
              <Icon lib="fa" name="menu" color="text" />
              <span>Menü</span>
            </button>
          </Box>
          <Drawer
            open={barrierFreeDrawerOpen}
            onClose={() => setBarrierFreeDrawerOpen(false)}
          >
            <Box
              className={classes.drawerHeader}
              px={3}
              pt={2}
              pb={6}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading type="h2">Barrierefreiheit</Heading>
            </Box>
            <Box px={3}>
              <Heading type="h3">Wie barrierefrei ist diese Webseite?</Heading>
              <Text paragraph>
                <Link
                  style={{ textDecoration: 'underline' }}
                  href="/barrier-free-policy"
                >
                  Erklärung zur Barrierefreiheit
                </Link>
              </Text>
              <Heading type="h3">
                Wen können Sie bei Anmerkungen oder Fragen zur digitalen
                Barrierefreiheit (Feedbackoption) kontaktieren?
              </Heading>
              <Text paragraph>
                Name: Frau Haufe
                <br />
                E-Mail:{' '}
                <a href="mailto:onlineredaktion@seninnds.berlin.de">
                  onlineredaktion@seninnds.berlin.de
                </a>
                <br />
                Telefon: (030) 90223-2657
              </Text>
              <Heading type="h3">
                Wo gibt es zusätzliche Informationen zur Barrierefreiheit im
                Land Berlin?
              </Heading>
              <Text paragraph>
                <a
                  href="https://www.berlin.de/moderne-verwaltung/barrierefreie-it/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    gap: '.25rem'
                  }}
                >
                  Barrierefreie Informations- und Kommunikationstechnik (IKT)
                  <Icon
                    size="small"
                    lib="fa"
                    name="external-link"
                    color="link"
                  />
                </a>
              </Text>
            </Box>
          </Drawer>
          <Drawer
            open={menuDrawerOpen}
            onClose={() => setMenuDrawerOpen(false)}
          >
            <Box
              className={classes.drawerHeader}
              px={3}
              pt={2}
              pb={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading type="h2">Menü</Heading>
            </Box>
            <nav className={classes.drawerNavigation}>
              <ul>
                {MENU({
                  intl,
                  portalBookingRequests
                }).map(item => (
                  <li key={item.title}>
                    {item.href ? (
                      <Link href={item.href}>{item.title}</Link>
                    ) : (
                      <Clickable onClick={item.onClick}>
                        <a>{item.title}</a>
                      </Clickable>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </Drawer>
        </Topbar>
      </header>
      <Box className={classes.layout}>
        <Box />
        <Box className={classes.content} role="main">
          {children}
          <Spacing y={4} />
        </Box>
        <Box />
      </Box>
      <Footer
        intlBackToTop={intl.formatMessage({
          id: 'go_to_page_top',
          defaultMessage: 'Go to page top'
        })}
        color="secondary"
        sections={FOOTER_SECONDARY(intl)}
        link={(title, href) => <Link href={href}>{title}</Link>}
      />
      <Footer
        color="primary"
        logo={
          <a
            href="https://www.berlin.de/"
            title={intl.formatMessage({
              id: 'go_to_homepage',
              defaultMessage: 'Go to homepage of Berlin.de'
            })}
          >
            <Image
              src={berlinLogoNegativ}
              alt="Berlin"
              height={40}
              width={120}
            />
          </a>
        }
        sections={FOOTER_PRIMARY(intl)}
        link={(title, href) => <Link href={href}>{title}</Link>}
        claim={intl.formatMessage(
          {
            id: 'impress_hint',
            defaultMessage: 'impress hint'
          },
          {
            impressUrl: (
              <a href="https://www.berlin.de/wir-ueber-uns-be/impressum/">
                {intl.formatMessage({
                  id: 'impress',
                  defaultMessage: 'Impressum'
                })}
              </a>
            )
          }
        )}
      />
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

Layout.defaultProps = {};

export default Layout;
